import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import { intcomma } from "journalize"
import { Button, Col, Row } from "react-bootstrap"
import { VscTriangleUp, VscTriangleDown } from "react-icons/vsc"
import queryString from "query-string"

import VizContext from "../context/vizcontext"
import TimeGroupDropdown from "../components/timegroupdropdown"
import Legend from "../components/legend"
import { StackedAreaChart } from "../components/stackedareachart"
import { getCurrentCharge, getChargeDescription } from "../components/utils"
import ContactForm from "../components/contactform"
import AllRace from './piecharts/all-race'
import AllGender from './piecharts/all-gender'
import RaceMale from './piecharts/race-male'
import RaceFemale from './piecharts/race-female'
import GenderByBlack from './piecharts/gender-black'
import GenderByWhite from './piecharts/gender-white'
import GenderByLatinx from './piecharts/gender-latinx'
import GenderByOther from './piecharts/gender-other'
import { getHTML } from "../components/utils"


// based on https://stackoverflow.com/questions/10970078/modifying-a-query-string-without-reloading-the-page
function putQueryParam (key, value) {
  if (typeof window !== 'undefined' && window.history.pushState) {
    const queryParams = queryString.parse(window.location.search)
    queryParams[key] = value
    let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + queryString.stringify(queryParams)
    window.history.pushState({path: newurl}, '', newurl)
  }
}

const setChargeState = (vizState, e) => {
  vizState.setCharge(e.target.value)
  putQueryParam('charge', e.target.value)
}

const DetailUnit = ({data, vizState, intl, chargeCutoff}) => {
  const currentChargeTotal = vizState.charge === 'all' ? data.Courts.category[0].charges : data.Courts.charges.filter(d => d.ucr.charge_slug === vizState.charge)[0].charges

  if (currentChargeTotal >= chargeCutoff) {
    var selectedData = {}
    if (vizState.charge === "all") {
      selectedData.areaChart = data.Courts[`analysis_category_counts_by_${vizState.timeGroup}_stacked`]
      selectedData.pieChartRace = data.Courts[`analysis_category_counts_all_time_race`]
      selectedData.pieChartSex = data.Courts[`analysis_category_counts_all_time_sex`]
      selectedData.pieChartRaceMale = data.Courts[`analysis_category_counts_all_time_race_male`]
      selectedData.pieChartRaceFemale = data.Courts[`analysis_category_counts_all_time_race_female`]
      selectedData.pieChartSexBlack = data.Courts['analysis_category_counts_all_time_sex_black']
      selectedData.pieChartSexWhite = data.Courts['analysis_category_counts_all_time_sex_white']
      selectedData.pieChartSexLatinx = data.Courts['analysis_category_counts_all_time_sex_latinx']
      selectedData.pieChartSexOther = data.Courts['analysis_category_counts_all_time_sex_other']
    } else {
      selectedData.areaChart = data.Courts[`analysis_charge_counts_by_${vizState.timeGroup}_stacked`].filter(d => d.ucr.charge_slug === vizState.charge)
      selectedData.pieChartRace = data.Courts[`analysis_charge_counts_all_time_race`].filter(d => d.ucr.charge_slug === vizState.charge)
      selectedData.pieChartRaceFemale = data.Courts[`analysis_charge_counts_all_time_race_female`].filter(d => d.ucr.charge_slug === vizState.charge)
      selectedData.pieChartRaceMale = data.Courts[`analysis_charge_counts_all_time_race_male`].filter(d => d.ucr.charge_slug === vizState.charge)
      selectedData.pieChartSex = data.Courts[`analysis_charge_counts_all_time_sex`].filter(d => d.ucr.charge_slug === vizState.charge)
      selectedData.pieChartSexBlack = data.Courts['analysis_charge_counts_all_time_sex_black'].filter(d => d.ucr.charge_slug === vizState.charge)
      selectedData.pieChartSexWhite = data.Courts['analysis_charge_counts_all_time_sex_white'].filter(d => d.ucr.charge_slug === vizState.charge)
      selectedData.pieChartSexLatinx = data.Courts['analysis_charge_counts_all_time_sex_latinx'].filter(d => d.ucr.charge_slug === vizState.charge)
      selectedData.pieChartSexOther = data.Courts['analysis_charge_counts_all_time_sex_other'].filter(d => d.ucr.charge_slug === vizState.charge)
    }


    var label = getChargeDescription(
      getCurrentCharge(vizState, data.Courts.charges),
      intl
    )

    
    const raceColors = ['#956FFF', '#08A333', '#dadada', '#F09518']
    const allGenderColors = ['#dadada', '#4D4D4D', '#9A9A9A', '#F09518']
    const purpleContrast = ['#e0dee3', '#9792a0', '#9A9A9A', '#dadada']
    const orangeContrast = ['#e3e2dd', '#918778', '#9A9A9A', '#dadada']
    const greenConstrast = ['#e1e5e1', '#78877c', '#9A9A9A', '#dadada']
    const chargesByOthers = ['#e3e3e3', '#757575', '#ababab', '#cbb8ff']

    return (
      <>
        <section>
          <Row
            className="header-dropdowns"
            style={{
              margin: '0 1rem 1rem',
              display: 'flex',
              alignItems: 'center'
            }}>
            <Col sm={8}>
              <TimeGroupDropdown
                charges={data.Courts.charges}
                vizState={vizState}
                intl={intl}
              />
            </Col>
            <Col sm={4}>
              <Legend
                intl={intl}
              />
            </Col>
          </Row>
          <StackedAreaChart
            data={selectedData.areaChart}
            timeGroup={vizState.timeGroup}
            intl={intl}
          />
        </section>
        <section style={{ marginTop: '40px', marginBottom: '40px'}}>
          <Row style={{ marginBottom: '4rem' }}>
            <Col md={8}>
              <h2 className="race-gender-header">
                {intl.formatMessage({
                  id: 'demographics.race_gender_header',
                  defaultMessage: 'Race and Gender'
                })}
              </h2>
              <div
                className='demographics-copy'
                dangerouslySetInnerHTML={{
                  __html: getHTML(data),
                }}
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: '1rem'}}>
            <Col>
              <h3 className="race-gender-header">
                {intl.formatMessage({
                  id: 'demographics.charges_race_header',
                  defaultMessage: 'Charges by Race and Ethnicity'
                })}
              </h3>
            </Col>
          </Row>
          <Row md={3} lg={4} xl={5}>
            <Col className='pie-divider'>
              <AllRace
                intl={intl}
                data={selectedData.pieChartRace} 
                colors={raceColors}
              />
            </Col>
            <Col>
              <RaceMale
                intl={intl}
                data={selectedData.pieChartRaceMale}
                colors={raceColors}
              />
            </Col>
            <Col>
              <RaceFemale 
                intl={intl}
                data={selectedData.pieChartRaceFemale}
                colors={raceColors}
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: '1rem', marginTop: '5rem'}}>
            <Col>
              <h3 className="race-gender-header">
                {intl.formatMessage({
                  id: 'demographics.charges_gender_header',
                  defaultMessage: 'Charges by Gender'
                })}
              </h3>
            </Col>
          </Row>
          <Row>
            <Col className='pie-divider'>
              <AllGender
                intl={intl}
                data={selectedData.pieChartSex}
                colors={allGenderColors}
              />
            </Col>
            <Col>
              <GenderByBlack 
                intl={intl}
                data={selectedData.pieChartSexBlack}
                colors={purpleContrast}
              />
            </Col>
            <Col>
              <GenderByWhite 
                intl={intl}
                data={selectedData.pieChartSexWhite}
                colors={orangeContrast}
              />
            </Col>
            <Col>
              <GenderByLatinx 
                intl={intl}
                data={selectedData.pieChartSexLatinx}
                colors={greenConstrast}
              />
            </Col>
            <Col>
              <GenderByOther 
                intl={intl}
                data={selectedData.pieChartSexOther}
                colors={chargesByOthers}
              />
            </Col>
          </Row>
        </section>
      </>
    )
  } else {
    return (
      <Row className="header-dropdowns" style={{ margin: '2rem 0' }}>
        {intl.formatMessage(
          {
            id: "charges.too_few_cases",
            defaultMessage: "Too few cases to visualize."
          }
        )}
      </Row>
    )
  }
}


class DetailUnits extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      showMore: false
    }
  }

  // if user goes back, reload the page to make sure we have the
  // latest querystring https://stackoverflow.com/a/48925019
  componentDidMount () {
    if (typeof window !== 'undefined') {
      window.addEventListener("popstate", () => {
        window.location.reload()
      })
    }
  }

  render () {
    const { data, intl } = this.props
    const category = data.Courts.category[0]
    const charges = data.Courts.charges
    const chargeCutoff = data.site.siteMetadata.chargeCutoff

    const chargesOverCutoff = charges.filter(d => d.charges >= chargeCutoff)
    const chargesUnderCutoff = charges.filter(d => d.charges < chargeCutoff)

    return (
      <VizContext.Consumer>
        {vizState => {
          return (
            <>
              <Row>
                <Col>
                  <p className="charges-header">
                    {intl.formatMessage({
                      id: "headers.charges",
                      defaultMessage: "Charges"
                    })}
                  </p>

                  <Button
                    className={vizState.charge === 'all' ? 'btn-charges-active' : 'btn-charges'}
                    value='all'
                    onClick={(e) => setChargeState(vizState, e)}
                  >
                    {intl.formatMessage({
                      id: "headers.showall",
                      defaultMessage: "Show all"
                    })} ({intcomma(category.charges)})
                  </Button>

                  {chargesOverCutoff.map((charge, i) => {
                    const chargeDescription = getChargeDescription(charge, intl)
                    return (
                      <Button
                        className={vizState.charge === charge.ucr.charge_slug ? 'btn-charges-active' : 'btn-charges'}
                        value={charge.ucr.charge_slug}
                        onClick={(e) => setChargeState(vizState, e)}
                      >
                        {chargeDescription} ({intcomma(charge.charges)})
                      </Button>
                    )
                  })}

                  {
                    chargesUnderCutoff.length > 0 &&
                    chargesUnderCutoff.map((charge, i) => {
                      const chargeDescription = getChargeDescription(charge, intl)
                      return (
                        <Button
                          className={this.state.showMore ? 'btn-charges-disabled' : 'hidden' }
                          value={charge.ucr.charge_slug}
                        >
                          {chargeDescription} ({intcomma(charge.charges)})
                        </Button>
                      )
                    })
                  }

                  {
                    chargesUnderCutoff.length > 0 &&
                    <>
                      <Button
                        className={this.state.showMore ? 'hidden' : 'btn-charges' }
                        onClick={
                          () => this.setState({ showMore: !this.state.showMore })
                        }
                      >
                        {intl.formatMessage({
                          id: "headers.seemore",
                          defaultMessage: "See more"
                        })} <VscTriangleDown />
                      </Button>

                      <Button
                        className={this.state.showMore ? 'btn-charges' : 'hidden' }
                        onClick={
                          () => this.setState({ showMore: !this.state.showMore })
                        }
                      >
                        {intl.formatMessage({
                          id: "headers.seeless",
                          defaultMessage: "See less"
                        })} <VscTriangleUp />
                      </Button>
                    </>
                  }

                  <p
                    style={{
                      fontSize: '1.2rem',
                      fontStyle: 'italic',
                      paddingTop: '0.5rem'
                    }}
                  >
                    {intl.formatMessage(
                      {
                        id: "headers.cutoff",
                        defaultMessage: "Detailed charts are unavailable for charges with fewer than {charge_cutoff} cases."
                      },
                      {
                        charge_cutoff: chargeCutoff
                      }
                    )}
                  </p>

                  <DetailUnit
                    data={data}
                    vizState={vizState}
                    intl={intl}
                    chargeCutoff={chargeCutoff}
                  />
                </Col>
              </Row>

              <Row style={{ marginTop: '4rem'}}>
                <Col>
                  <ContactForm
                    data={data}
                    intl={intl}
                  />
                </Col>
              </Row>
            </>
          )
        }}
      </VizContext.Consumer>
    )
  }
}

export default injectIntl(DetailUnits)
