// based on http://recharts.org/en-US/examples/CustomActiveShapePieChart
import React, { PureComponent } from 'react'
import { Pie, Cell, Sector } from 'recharts'
import { PieChart as RePieChart } from 'recharts'
import { intcomma } from 'journalize'
import { FormattedMessage } from 'gatsby-plugin-intl'
import { Row, Col } from 'react-bootstrap'

const renderActiveShape = (props) => {
  const {
    cx, cy, innerRadius, outerRadius, startAngle, endAngle,
    fill, payload, percent, value,
  } = props

  return (
    <g>
      <text x={cx} y={cy} dy={-10} textAnchor="middle" fill='#000000' style={{fontWeight: 'bold'}}>
        <FormattedMessage
          id={`demographics.${payload.name ? payload.name.toLowerCase(): 'null'}`}
        />
      </text>
      <text x={cx} y={cy} dy={7} textAnchor="middle" fill="#333">
        <FormattedMessage
          id="charges.casescount"
          defaultMessage={`{cases} cases`}
          values={{cases: (intcomma(value))}}
        />
      </text>
      <text x={cx} y={cy} dy={24} textAnchor="middle" fill="#999">
        {`${(percent * 100).toFixed(2)}%`}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
    </g>
  )
}


class PieChart extends PureComponent {
  
  state = {
    // find the index of the value with the max charge count
    // and set it as the default
    // https://stackoverflow.com/a/30850912
    activeIndex: this.props.data.map(
      x => x.charges
    ).reduce(
      (iMax, x, i, arr) => x > arr[iMax] ? i : iMax, 0
    ),
  }

  onPieEnter = (data, index) => {
    this.setState({
      activeIndex: index,
    })
  }

  render () {
    return (
      <>
        <Row>
          <Col md={{ span: 12}}>
            <h4 className='race-gender-pie-header'>
              {this.props.label}
            </h4>
          </Col>
        </Row>

        <RePieChart height={200}  width={200} style={{ marginBottom: '30px'}}> 
          <Pie
            activeIndex={this.state.activeIndex}
            activeShape={renderActiveShape}
            data={this.props.data}
            innerRadius={65}
            outerRadius={90}
            fill="#8884d8"
            dataKey="charges"
            onMouseEnter={this.onPieEnter}
          >
            {
              this.props.data.map((entry, index) => <Cell key={`cell-${index}`} fill={this.props.colors[index % this.props.colors.length]} />)
            }
          </Pie>
        </RePieChart>
      </>
    )
  }
}

export default PieChart
