import React from 'react'
import { graphql } from 'gatsby'
import { injectIntl } from "gatsby-plugin-intl"

import SEO from "../components/seo"
import Layout from '../components/layout'
import Hero from '../components/hero'
import DetailUnits from "../components/detailunits"
import OffenseText from "../components/offensetext"
import { getCategoryLabel } from "../components/utils"

const CategoryPage = ({ intl, data }) => {
  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: "title" })} />
      <Hero
        label={intl.formatMessage({ id: "category.hero-label" })}
      >
        <h1 className="category-headline">
          {getCategoryLabel(data, intl)}
        </h1>
        <br />
        <OffenseText
          category={data.Courts.category[0].offenseCategoryByOffenseCategory}
          intl={intl}
        />
      </Hero>
      <div className="page-body">
        <DetailUnits
          data={data}
          intl={intl}
        />
      </div>
    </Layout>
  )
}

export default injectIntl(CategoryPage)

export const query = graphql`
  query($offenseCategory: String!, $language: String!) {
    site {
      siteMetadata {
        chargeCutoff
      }
    }
    current: markdownRemark(
      fields: { page: { pageId: { eq: "race-gender-copy" }, lang: { eq: $language } } }
    ) {
      html
      fields {
        page {
          path
          lang
        }
      }
    }
    default: markdownRemark(
      fields: { page: { pageId: { eq: "race-gender-copy" }, lang: { eq: "en" } } }
    ) {
      html
      fields {
        page {
          path
          lang
        }
      }
    }
    Courts {
      category:analysis_category_counts_all_time (
        where: {
          offense_category: {_eq: $offenseCategory}
        }
      ) {
        offense_category
        charges
        offenseCategoryByOffenseCategory {
          category_slug
          offense_category
          offense_category_es
          what_is_it
          what_is_it_es
          data_shows
          data_shows_es
        }
      }
      charges:analysis_charge_counts_all_time(
        order_by: {charges: desc},
        where: {ucr: {offense_category: {_eq: $offenseCategory}}},
      ) {
        charges
        ucr {
          id
          ucr_code
          description
          description_es
          charge_slug
        }
      }
      analysis_charge_counts_all_time_race_female(
        order_by: {name: asc},
        where: {
          ucr: {offense_category: {_eq: $offenseCategory}}
        }
      ) {
        ucr {
          charge_slug
        }
        name
        charges
      }
      analysis_charge_counts_all_time_race_male(
        order_by: {name: asc},
        where: {
          ucr: {offense_category: {_eq: $offenseCategory}}
        }
      ) {
        ucr {
          charge_slug
        }
        name
        charges
      }
      analysis_charge_counts_all_time_race(
        order_by: {name: asc},
        where: {
          ucr: {offense_category: {_eq: $offenseCategory}}
        }
      ) {
        ucr {
          charge_slug
        }
        name
        charges
      }
      analysis_charge_counts_all_time_sex(
        order_by: {name: asc},
        where: {
          ucr: {offense_category: {_eq: $offenseCategory}}
        }
      ) {
        ucr {
          charge_slug
        }
        name
        charges

      }
      analysis_charge_counts_all_time_sex_black(
        order_by: {name: asc},
        where: {
          ucr: {offense_category: {_eq: $offenseCategory}}
        }
      ) {
        ucr {
          charge_slug
        }
        name
        charges
      }
      analysis_charge_counts_all_time_sex_white(
        order_by: {name: asc},
        where: {
          ucr: {offense_category: {_eq: $offenseCategory}}
        }
      ) {
        ucr {
          charge_slug
        }
        name
        charges
      }
      analysis_charge_counts_all_time_sex_latinx(
        order_by: {name: asc},
        where: {
          ucr: {offense_category: {_eq: $offenseCategory}}
        }
      ) {
        ucr {
          charge_slug
        }
        name
        charges
      }
      analysis_charge_counts_all_time_sex_other(
        order_by: {name: asc},
        where: {
          ucr: {offense_category: {_eq: $offenseCategory}}
        }
      ) {
        ucr {
          charge_slug
        }
        name
        charges
      }
      analysis_category_counts_all_time_race_female(
        order_by: {name: asc},
        where: {
          offense_category: {_eq: $offenseCategory}
        }
      ) {
        offense_category
        name
        charges
      }
      analysis_category_counts_all_time_race_male(
        order_by: {name: asc},
        where: {
          offense_category: {_eq: $offenseCategory}
        }
      ) {
        offense_category
        name
        charges
      }
      analysis_category_counts_all_time_race(
        order_by: {name: asc},
        where: {
          offense_category: {_eq: $offenseCategory}
        }
      ) {
        offense_category
        name
        charges
      }
      analysis_category_counts_all_time_sex(
        order_by: {name: asc},
        where: {
          offense_category: {_eq: $offenseCategory}
        }
      ) {
        offense_category
        name
        charges
      }
      analysis_category_counts_all_time_sex_black(
        order_by: {name: asc},
        where: {
          offense_category: {_eq: $offenseCategory}
        }
      ) {
        offense_category
        name
        charges
      }
      analysis_category_counts_all_time_sex_white(
        order_by: {name: asc},
        where: {
          offense_category: {_eq: $offenseCategory}
        }
      ) {
        offense_category
        name
        charges
      }
      analysis_category_counts_all_time_sex_latinx(
        order_by: {name: asc},
        where: {
          offense_category: {_eq: $offenseCategory}
        }
      ) {
        offense_category
        name
        charges
      }
      analysis_category_counts_all_time_sex_other(
        order_by: {name: asc},
        where: {
          offense_category: {_eq: $offenseCategory}
        }
      ) {
        offense_category
        name
        charges
      }
      analysis_category_counts_all_time_race(
        order_by: {name: asc},
        where: {
          offense_category: {_eq: $offenseCategory}
        }
      ) {
        offense_category
        name
        charges
      }
      analysis_charge_counts_by_month_stacked(
        order_by: {case_date: asc},
        where: {
          ucr: {offense_category: {_eq: $offenseCategory}}
        }
      ) {
        case_date
        charges_f
        charges_m
        ucr {
          charge_slug
        }
      }
      analysis_charge_counts_by_year_stacked(
        order_by: {case_date: asc},
        where: {
          ucr: {offense_category: {_eq: $offenseCategory}}
        }
      ) {
        case_date
        charges_f
        charges_m
        ucr {
          charge_slug
        }
      }
      analysis_category_counts_by_month_stacked(
        order_by: {case_date: asc},
        where: {
          offense_category: {_eq: $offenseCategory}
        }
      ) {
        case_date
        charges_f
        charges_m
      }
      analysis_category_counts_by_year_stacked(
        order_by: {case_date: asc},
        where: {
          offense_category: {_eq: $offenseCategory}
        }
      ) {
        case_date
        charges_f
        charges_m
      }
    }
  }
`
