import React from 'react'
import PieChart from './piechart'
import makeLabel from './make-label'

export default function GenderByOther ({ intl, data, colors }) {
  return (
    <PieChart
      label={makeLabel('demographics.gender_by_other', 'charges by gender, other or unknown race or ethnicity', intl, data)}
      data={data}
      colors={colors}
    />
  )
}
