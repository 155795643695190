import React from 'react'
import PieChart from './piechart'
import makeLabel from './make-label'

export default function GenderByLatinx ({ intl, data, colors }) {
  return (
    <PieChart
      label={makeLabel('demographics.gender_by_latinx', 'charges by gender, Latinx alone', intl, data)}
      data={data}
      colors={colors}
    />
  )
}
