import React from 'react'
import PieChart from './piechart'
import makeLabel from './make-label'

export default function AllGenders ({ intl, data, colors }) {
  return (
    <PieChart
      label={makeLabel('demographics.gender', 'charges by gender, all races and ethnicities', intl, data)}
      data={data}
      colors={colors}
    />
  )
}
