export default function makeLabel (id, defaultMessage, intl, data) {
  const total = data.reduce((a, b) => a + (b['charges'] || 0), 0)

  const labelDetails = {
    id: id,
    defaultMessage: defaultMessage
  }

  return `${intl.formatMessage(labelDetails)} (${total.toLocaleString()}).`
}
