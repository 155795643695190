import React from 'react'
import PieChart from './piechart'
import makeLabel from './make-label'

export default function GenderByBlack ({ intl, data, colors }) {
  return (
    <PieChart
      label={makeLabel('demographics.gender_by_black', 'charges by gender, Black alone', intl, data)}
      data={data}
      colors={colors}
    />
  )
}
